import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
flex-direction: row;
align-items: center;
padding: 12px 16px;
gap: 8px;

position: absolute;
width: 350px;
height: 64px;
right: 36px;
top: 22px;

/* red/--DS-color-red-light */

background: ${props => props.color};
border-radius: 8px;
`;

export const Text = styled.span`
  width: 262px;
height: 16px;

font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */


/* Auxiliar/ Texto Erro */

color: ${props => props.color};


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 1;
align-self: ${props => props.color == '#2B641E' ? 'center' : 'flex-start'};
`;