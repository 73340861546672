import styled from 'styled-components';

export const HomeWrapper = styled.main`
  /* Desktop */
  @media (min-width:1025px) {
    display: flex;
  }
`;

export const Container = styled.section`
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  padding: 20px 16px;

  /* Tablet */
  @media (min-width:768px) {
    padding: 28px 30px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    padding: 0;
  }
`;

export const SearchContainer = styled.div`
  padding: 16px;
  position: fixed;
  top: 92px;
  width: calc(100% - 32px);
  background: var(--background);
  z-index: 1;
  
  /* Tablet */
  @media (min-width:768px) {
    background: var(--searchBackground);
    width: calc(100% - 60px);
    padding: 28px 30px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    margin-left: 200px;
    width: calc(100% - 200px);
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }
`;

export const SearchContent = styled.div`

  /* Desktop */
  @media (min-width:1025px) {
    width: 685px;
  }
`;

export const PageContainer = styled.div`
  background: var(--background);
  margin-top: 212px;
  display: flex;
  flex-direction: column;

  /* Tablet */
  @media (min-width:768px) {
    padding: 40px 0;
  }

  /* Desktop */
  @media (min-width:1025px) {
    margin-left: 200px;
    padding: 24px 0;
    margin-top: 80px;
    width: calc(100% - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  color: var(--primaryTextColor);
  line-height: 150%;
  margin-bottom: 8px;

  /* Tablet */
  @media (min-width:768px) {
    font-size: 20px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    font-size: 16px;
  }
`
export const InitialStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Tablet */
  @media (min-width:768px) {
    padding: 40px 30px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    padding: 0;
  }
`;

export const InitialStateMessage = styled.span`
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 150%;
  color: var(--tertiaryTextColor);

  /* Desktop */
  @media (min-width:1025px) {
    font-size: 20px;
  }
`;

export const InitialStateImage = styled.img`
  margin: 50px 0;

  /* Tablet */
  @media (min-width:768px) {
    width: 414px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    width: 337px;
    margin-bottom: 0;
  }
`;

export const ListContainer = styled.ul`
  margin: 0 -16px;
  margin-bottom: 40px;

  /* Tablet */
  @media (min-width:768px) {
    margin: 0;
    margin-bottom: 40px;
  }

  /* Desktop */
  @media (min-width:1025px) {
    width: calc(670px - 52px);
    padding: 0 26px;
  }
`;

export const ListTitle = styled.span`
  display: block;
  margin-bottom: 24px;
  margin-left: 16px;
  color: var(--primaryTextColor);
  font-family: 'Roboto', sans-serif;

  /* Tablet */
  @media (min-width:768px) {
    margin-left: 0;
  }

  /* Desktop */
  @media (min-width:1025px) {
    margin-left: -26px;
  }
`;

export const EmptyListWrapper = styled.div`
  margin: 0 16px;
  text-align: center;

  /* Desktop */
  @media (min-width:1025px) {
    text-align: left;
  }
`;

export const EmptyList = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 150%;
  color: var(--tertiaryTextColor);
  
`;

export const Paragraph = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 150%;
  color: var(--tertiaryTextColor);
`;

export const Observation = styled.span`
 font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 150%;
  color: var(--tertiaryTextColor);
`

export const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid rgba(17, 17, 19, 0.2);
  border-radius: 4px;
  background: var(--background);
  margin-top: 16px;
  width: 300px;
`;

export const TextInput = styled.input`
  width: 100%;
  background: #FFF;
  height: 30px;
  border: none;
  padding: 0 10px;
  outline: none;

  &::placeholder {
    color: #B9B9BB;
    font-size: 14px;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  width: 200px;
`;

export const Button = styled.button`
  border-style: none;
  border-radius: 12px;
  background: var(--highlightColor);
  color: var(--highlightText);
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;


export const Option = styled.div`
  height: auto;
  width: 50%;
  box-sizing: border-box;
  margin-top: 32px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
  border: 1px solid rgba(17, 17, 19, 0.2);
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 4px;
  background: var(--background);
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
`;


export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #ccc;
  }
  &:checked + ${Item} {
    background: var(--highlightColor);;
    border: 2px solid var(--highlightColor);;
  }
  &:checked + ${RadioButtonLabel} {
    background: var(--highlightColor);;
    border: 1px solid var(--highlightColor);;
  }
`;


export const Diveder = styled.hr`
  border-top: 3px solid #bbb;
  width: 50%;
  margin: 32px 0 32px 0;
`