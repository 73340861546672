/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Container,
  PageContainer,
  Paragraph,
  Observation,
  ContainerInput,
  TextInput,
  ButtonContainer,
  Button,
  Option,
  Item,
  RadioButton,
  RadioButtonLabel,
  Diveder
} from './styles';
import { Header } from 'components/Header';

import { useState, ChangeEvent } from 'react';

import { api } from 'config/api';
import { Loader } from 'components/Loader';
import { MessageElement } from 'components/Message';
import { HiOutlineExclamationCircle, HiCheckCircle, HiInformationCircle } from 'react-icons/hi';

export const Home = () => {

  const [order, setOder] = useState('');
  const [loading, setLoading] = useState(false);

  const [orderId, setOderId] = useState('');
  const [loadingOrderInfo, setLoadingOrderInfo] = useState(false);
  
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  
  const [select, setSelect] = useState('');
  const [optionOne, setOptionOne] = useState('')
  const [optionTwo, setOptionTwo] = useState('')


  const handleForceOrder = async () => {
    setLoading(true);
    try {
      let response;
      if(select) {
        response = await api.post('force-order', {
          'orderId': order,
          'cep': select,
        });
      } else {
        response = await api.post('force-order', {
          'orderId': order,
        });
      }
      

      if (response.status !== 200) {
        setMessage(response.data)
        setError(true)
      setMessageType('error')
      } else {
        setMessage(response.data)
        setError(false)
      setMessageType('success')
      }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(true)
      setMessage(error.response.data)
      setMessageType('error')
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError(false);
        setMessage('');
      }, 10000);
    }
  }

  const handleSearchOrder = async () => {
    setLoadingOrderInfo(true);
    try {
      const response = await api.get(`force-order/${orderId}`);

      console.log(response);
      if (response.status !== 200) {
        setMessage(response.data)
        setError(true)
        setMessageType('error')
      } else {
        const {message, sj, vtexCep } = response.data;

        if(sj && vtexCep) {
          setSelect(sj)
          setOptionOne(sj)
          setOptionTwo(vtexCep)
        }
        setMessage(message)
        setError(false)
        setMessageType('info');
      }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(true)
      setMessage(error.response.data)
      setMessageType('error')
    } finally {
      setLoadingOrderInfo(false);
      setTimeout(() => {
        setError(false);
        setMessage('');
      }, 10000);
    }
  }

  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    setSelect(value);
  };

  const handleMessage = () => {
    switch (messageType) {
      case 'info':
       return (
          <MessageElement text={message} primaryColor={'#D7F1FD'} secondaryColor={'#0C2A75'} icon={HiInformationCircle} />
       ) 
      case 'success':
        return (
          <MessageElement text={message} primaryColor={'#EDFBD8'} secondaryColor={'#2B641E'} icon={HiCheckCircle} />
        )
      case 'error':
        return (
          <MessageElement text={message} primaryColor={'#FCE8DB'} secondaryColor={'#BA1600'} icon={HiOutlineExclamationCircle} />
        )
      default:
        return null;
    }
  }

  return (
    <>
      {message && handleMessage()}
      <Header />

        <Container>

          <PageContainer>
          <p>{error}</p>
          <>
          {loadingOrderInfo ? <><Loader message1='Estamos processando' message2='a integração seu pedido...'/></> : 
          <>
            <Paragraph>
              Para consultar as informações do pedido, coloque o numero do pedido da VTEX
            </Paragraph>
            <Observation>
              Obs: O numero deve seguir este padrão 1281780504400-01.
            </Observation>
            <ContainerInput>
              <TextInput 
                placeholder='Digite o numero do pedido' 
                onChange={(e: ChangeEvent<HTMLInputElement>) => setOderId(e.target.value)}
                onClick={() => setOderId('')}
                value={orderId}
              />
            </ContainerInput>
            <ButtonContainer>
              <Button onClick={() => handleSearchOrder()}> Pesquisar </Button>
            </ButtonContainer>

            {select && 
            <Option>
              <Item>
                <RadioButton
                  type="radio"
                  name="radio"
                  value={optionOne}
                  checked={select === optionOne}
                  onChange={event => handleSelectChange(event)}
                />
                <RadioButtonLabel />
                <div>Usar CEP do SJ: {optionOne}</div>
              </Item>
              <Item>
                <RadioButton
                  type="radio"
                  name="radio"
                  value={optionTwo}
                  checked={select === optionTwo}
                  onChange={event => handleSelectChange(event)}
                />
                <RadioButtonLabel />
                <div>Usar CEP da VTEX: {optionTwo}</div>
              </Item>
             </Option>
             }
            </>
          }

          <Diveder />

          {loading ? <><Loader message1='Estamos processando' message2='a integração seu pedido...'/></> : <>
            <Paragraph>
              Para forçar a integração do pedido, coloque o numero do pedido da VTEX
            </Paragraph>
            <Observation>
              Obs: O numero deve seguir este padrão 1281780504400-01
            </Observation>
            <ContainerInput>
              <TextInput 
                placeholder='Digite o numero do pedido' 
                onChange={(e: ChangeEvent<HTMLInputElement>) => setOder(e.target.value)}
                onClick={() => setOder('')}
                value={order}
              />
            </ContainerInput>
            <ButtonContainer>
              <Button onClick={() => handleForceOrder()}> Integrar </Button>
            </ButtonContainer>
            </>
          }
          </>
          </PageContainer>
            
        </Container>
    </>
  )
}
