import styled from 'styled-components';

export const Container = styled.header`
  height: 72px;
  background: var(--highlightColor);
  color: var(--highlightText);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  width: calc(100% - 32px);

  /* Desktop */
  @media (min-width:1025px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    width: 136px;
    padding: 24px 32px;
  }
`;

export const Divider = styled.div`
  display: none;

  /* Desktop */
  @media (min-width:1025px) {
    display: block;
    width: 100%;
    height: 1px;
    background: #A29FCC;
    margin: 24px 0;
  }
`;

export const GreetingContainer = styled.div`
  display: flex;

  /* Desktop */
  @media (min-width:1025px) {
    flex-direction: column;
  }
`;

export const Greeting = styled.span`
  font-weight: 600;
  line-height: 120%;

  /* Desktop */
  @media (min-width:1025px) {
    line-height: 150%;
  }
`;

export const UserName = styled.span`
  font-weight: 600;
  line-height: 120%;
  margin-left: 8px;

  /* Desktop */
  @media (min-width:1025px) {
    line-height: 150%;
    margin-left: 0;
  }
`;

export const SignOutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SignOutText = styled.span`
  font-family: 'Roboto', sans-serif;
  margin-right: 11px;
`;
