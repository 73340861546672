import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import mobile from './styles/themes/mobile'
import { GlobalStyle } from './styles/global';
import AuthProvider from './contexts/AuthContext';
import MessageProvider from 'contexts/MessageContext';
import { msalInstance } from './config/msalInstance';
import App from './App';


const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <MessageProvider>
          <ThemeProvider theme={mobile}>
            <>          
              <GlobalStyle />
              <App pca={msalInstance}/>
            </>
          </ThemeProvider>
        </MessageProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
);
