/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useMsal,useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Outlet } from 'react-router-dom';
import { msalInstance } from '../config/msalInstance';
import { loginRequest } from '../config/authConfig';
import useAuth from '../hooks/useAuth';
import { Login } from 'pages/Login';
import { Loader } from 'components/Loader';


export const PersistLogin = () => {
  const { instance, inProgress, } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { setAuth } = useAuth();
  
  const allowedUsers = [
    'paulo.lima@ext.medbeauty.com.br',
    'marcos.souza@medbeauty.com.br',
    'diego.pereira@medbeauty.com.br',
    'simei.machado@medbeauty.com.br',
    'thiago.sano@medbeauty.com.br',
    'vinicius.bastos@medbeauty.com.br'
  ]

  const account = msalInstance.getActiveAccount();
  
  useEffect(() => {
    const verifyRefreshToken = async () => {
      
      if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      }

      if(!allowedUsers.includes(account.username)) instance.logoutRedirect();

      const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account
      });
      
      setGraphData({...response.account} as any)
      setAuth({...response.account})

      return response;
    };

    if (!graphData && (inProgress === InteractionStatus.None)) {
      verifyRefreshToken();
    }
  }, [inProgress, graphData, instance]);

  const handleLoginPage = () => {
    if(inProgress) {
      if(inProgress === InteractionStatus.HandleRedirect || inProgress === InteractionStatus.Startup) return <Loader message1='Carregando...' message2=''/>
      return <Login /> ;
    }
    return <Outlet />
  }

  return allowedUsers.includes(account ? account.username : '') && isAuthenticated 
    ? <Outlet /> 
    : handleLoginPage()
}



