import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #fff;
    --highlightColor: #6460AA;
    --highlightText: #fff;
    --primaryTextColor: #6D6E71;
    --secondaryTextColor: #2F3132;
    --tertiaryTextColor: #B9B9BB;
    --success: #00E13F;
    --searchBackground: #F5F5F5;
    --cardBackground: #fcfcfc;
    --dividerColor: #ECECEC;
    --alert: #E86763;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
  }

  html {
    @media(max-width:1080px) {
      font-size: 93.75%;
    }

    @media(max-width:720px) {
      font-size: 87.5%;
    }
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    width: 100%;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
`;


