import Error from 'assets/amico.svg';
import { 
  Container,
  Wrapper,
  Title,
  Message,
  Image,
  StyledButton,
} from './styles';

export const ErrorScreen = ({ title, message }: { title: string, message: string }) => {
  const redirectToHome = () => {
    window.location.href = '/';
  }

  return (
    <Container>
      <Wrapper>
        <Title>{title}</Title>

        <Message>{message}</Message>

        <Image src={Error} />
        
        <StyledButton onClick={() => redirectToHome()}>Ir para início</StyledButton>
      </Wrapper>
    </Container>
  )
}