import { useState } from 'react';
import { MessageContext } from './MessageContext';

type Props = {
  children?: React.ReactNode;
}
export const MessageProvider = ({ children }: Props) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  return (
    <MessageContext.Provider value={{ message, setMessage, messageType, setMessageType }}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;
