/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MSAL
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { ProjectRouter } from './routes'
import { CustomNavigationClient } from './utils/NavigationClient';


type AppProps = {
  pca: IPublicClientApplication;
};

type Navigation = {
  pca: IPublicClientApplication;
  children: any;
}

const App = ({ pca }: AppProps) => {
  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <ProjectRouter />
      </MsalProvider>
    </ClientSideNavigation>
  );
}

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */ 
 const ClientSideNavigation = ({ pca, children }: Navigation) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

export default App;
